<template>
  <v-card>
    <v-card-title> Trask Ranch </v-card-title>
    <v-card-text>
      <p>
        The Spanish Five Ranch in Wasta, South Dakota, is owned and operated by
        Tom and Sheila Trask along with their children, Mark, Mick, and Tomilyn.
        Encompassing nearly 20,000 acres along the Cheyenne and Elk Creek
        tributaries, the ranch serves as the foundation for Dakota Trophy
        Adventures—a versatile business dedicated to hunting and outdoor
        recreation. From trophy Mule Deer and Whitetail to Antelope and Merriam
        turkeys, as well as fossils, fihsing and predator control thermal hunts!
        Dakota Trophy Adventures offers diverse hunting experiences. In addition
        to hunting, the ranch includes cattle, registered quarter horses, and
        personal hunting trips spanning from Alaska to Africa, various provinces
        in Canada, numerous western United States, and as far south as New
        Zealand and Australia. Dakota Trophy Adventures excels in helping
        clients discover exceptional hunting areas and enjoy the great outdoors.
        We take pride in providing excellent recommendations for outfitters we
        have hunted with worldwide.
      </p>
      <p>
        The Trask Family resides on a prairie ranch in South Dakota, a property
        with deep roots tracing back to Tom's parents, Mark and Winnie Trask.
        Tom Trask, born and raised in the Elm Springs area, has a familial
        connection to the land that goes back generations. His father played a
        pivotal role in establishing a commercial hunting operation in 1965,
        complementing their ranching activities in the Elm Springs region. Tom's
        grandfather, Mack Trask, homesteaded at the forks of the Cheyenne and
        Belle Fourche rivers, just north of our current location. Growing up in
        a family deeply involved in ranching and hunting, Tom spent winters
        trapping with his father and summers hunting fossils with his mother. He
        attended the local country school in Meade County, where his enthusiasm
        for sharing hunting adventures became well-known. Even during high
        school in Sturgis, Tom's passion for the ranching and hunting lifestyle
        persisted. Following his high school graduation, Tom briefly attended
        college at SDSU. However, his return home during Christmas revealed the
        challenges his family and cattle faced in a tough winter, prompting him
        to dedicate his efforts to their needs. This commitment underscored
        Tom's deep connection to both the land and the responsibilities that
        came with it.
      </p>
      <p>
        After partnering with his brother for a few years, Tom decided to
        separate the operation in 1985. In 1986, Tom married Shelia McDaniel,
        and together, they have continued to ranch and expand both the ranching
        and outfitting businesses. Shelia's roots are in the northeast of
        Philip, SD, where she grew up on a cattle and sheep ranch. Her early
        education took place in a rural school, and she later attended high
        school in Philip. During her country school days, the boy-to-girl ratio
        was 13-1, leading Shelia to embrace activities traditionally associated
        with boys, such as riding horseback to school and hunting rabbits along
        the way. Following her graduation, Shelia pursued higher education at
        SDSU and ultimately graduated from BHSU with a degree in business and
        education.
      </p>
      <p>
        In 1987, Tom assumed the role of booking for Dakota Trophy and has since
        expanded its services into other areas, including turkey hunts, antelope
        hunts, and archery hunting. Beyond the realm of hunting, both locally
        and globally, our endeavors involve putting up hay, raising cattle, and
        maintaining a select group of quarter horses. It's a common sight to
        witness the entire family actively engaged, whether in a hay field
        operating machinery or in a pasture or corral tending to the cattle.
      </p>
      <p>
        Tom and Shelia are proud parents of three children. In 1989, after
        serving hunters dinner, Tom rushed Shelia to the hospital where Mark was
        born prematurely – a fact that might be surprising given his robust
        health today. Mick followed in 1991, and Tomilyn in 1993. They all
        attended the local rural school until Mark transitioned to high school,
        prompting the decision to send all three to school in Philip. Each of
        them graduated from Philip High School. Following high school, Mark and
        Mick embarked on brief college careers and attended taxidermy school.
        Presently, both are back home, actively involved in ranching, hunting,
        and practicing taxidermy. Tomilyn pursued higher education, earning a
        degree in Mathematics Education, but has found that teaching is not her
        calling! She currently resides at home, contributing to ranch activities
        and handling booking and paperwork for Dakota Trophy. During your time
        at Dakota Trophy, you'll find all three siblings guiding, cooking,
        managing skinning and caping, and participating in meat processing. The
        Trask family has been blessed with two grandchildren, Levi and Annie,
        both attending the same rural school that Tom, Mark, Mick, and Tomilyn
        did. Levi, born in 2010, and Annie, born in 2014, reside on the ranch,
        actively assisting whenever they can, balancing their time between
        school and other activities.
      </p>
      <p>
        We share a passion for hunting and make the most of every opportunity
        whenever time and finances permit. Our hunting adventures have taken us
        to numerous provinces in Canada, across Alaska, and through many states
        in the lower 48. Additionally, we have established valuable connections
        with outfitters in Africa, New Zealand, and Australia. Engaging in
        conversations about hunting brings us joy, and our network in the world
        of hunting is extensive. If you're considering a hunting expedition,
        we're here to help, advise, or assist you in booking with a quality and
        reliable outfitter. It's important to note that we only recommend
        outfitters whom we have personally hunted with. For detailed insights
        and reviews, please check under the Reviews tab for links to the
        outfitters we confidently endorse!
      </p>
      <p>
        In conjunction with hunting, preserving trophies is an integral part of
        our endeavors. Tom has engaged in taxidermy for our purposes, and
        currently, Mark and Mick have taken up the craft after studying under
        Tom Matuska at the Northwest Iowa School of Taxidermy. Their work in
        taxidermy is primarily undertaken during the winter months or whenever
        they find time between their ranching commitments. For more details
        about Dakota Trophy Taxidermy, please explore the Taxidermy tab!
      </p>
      <p>
        For discussions about hunting, cattle, or horses, please feel free to
        contact us. We'd love to have a conversation with you!
      </p>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Ranch",
  data: function () {
    return {};
  },
};
</script>

<style>
</style>